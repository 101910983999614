@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;1,100&display=swap');

body {
  margin: 0;
  font-family: -apple-system, Poppins, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Code', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FAFAFA;
}

/* code {
  font-family: 'fira code', Poppins, Monaco, Consolas, 'Courier New',
    monospace;
}

.shail {
  background-image: url('./components/assets/images/sheilong.jpeg');
  background-size: contain;
  opacity: 0.70;
  overflow: hidden;
} */